<template>
  <div style="">
    <portal to="title-page">
      <h1>Reporte de reservaciones por tour</h1>
    </portal>
    <v-card
      elevation="2"
    >
      <v-card-text>
        <!-- <Loading :active="loader" :fullPage="false"/> -->
        <Loading :active="loading"/>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.startDate"
                  label="Fecha Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.startDate"
                @input="menuStartDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.endDate"
                  label="Fecha fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.endDate"
                @input="menuEndDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.tour"
              :items="tours"
              item-text="name"
              item-value="id"
              label="Tour"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.panel"
              :items="panels"
              label="Panel"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.rate"
              :items="rates"
              label="Tarifa"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.status"
              :items="status"
              label="Status"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="5" class="py-0">
            <v-btn
              class="mb-md-3"
              small
              elevation="2"
              color="primary"
              @click="searchReservations()"
            >
              <v-icon>mdi-magnify</v-icon> Buscar
            </v-btn>
            <v-btn 
              class="ml-2 mb-md-3 white--text"
              small 
              color="red"
              @click="generatePdf"
            >
              <v-icon>mdi-file-pdf-box</v-icon> Descargar
            </v-btn>
          </v-col>
          <!-- <v-col cols="6" sm="6" md="6">
            <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
          </v-col> -->
        </v-row>
        <div v-if="result" class="table-responsive">
          <table class="table table-bordered text-center">
            <thead>
              <tr>
                <th>Tour</th>
                <th>Reservaciones</th>
                <th>Adultos</th>
                <th>A.Mayor</th>
                <th>Niños</th>
                <th>Infantes</th>
              </tr>
            </thead>
            <tbody>
              <!-- <template
                v-for="(tour, index) in result.tours"
              > -->
                <!-- <tr v-for="(item, i) in tour.items" :key="i+index"> -->
                <tr v-for="(item, i) in result.tours" :key="i">
                  <td >{{ item.tour }}</td>
                  <td >{{ item.reservations }}</td>
                  <td >{{ item.adults }}</td>
                  <td >{{ item.elderlies }}</td>
                  <td >{{ item.children }}</td>
                  <td >{{ item.infants }}</td>
                </tr>
              <!-- </template> -->
            </tbody>
            <tfoot>
              <tr>
                <th>Totales</th>
                <th>{{ result.reservations }}</th>
                <th>{{ result.adults }}</th>
                <th>{{ result.elderlies }}</th>
                <th>{{ result.children }}</th>
                <th>{{ result.infants }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import Reservation from "@/models/Reservation"
import Tour from "@/models/Tour"
// import DailyPDF from "@/pdfs/reports/DailyPDF"
import ToursPDF from "@/pdfs/reports/ToursPDF"
import Loading from "@/components/Loading"
Vue.use('Loading', Loading);
import "@/assets/css/tables.css"
export default {
  name:'TaquillaToursReport',
  //components: { Loading },
  data() {
    let date = this.$moment()
    
    return {
      filters:{
        status: {name: 'checkin', value: 'checkin'},
        tour: '',
        rate: '',
        panel: '',
        startDate:date.format('YYYY-MM-DD'),
        endDate:date.format('YYYY-MM-DD'),
      },
      //date: '2021-02-12',
      reservationModel: new Reservation,
      //items: null,
      result: null,
      menuStartDate: false,
      menuEndDate: false,
      loading: false,
      tours: [{name: 'Todos', id: ''}],
      panels: [
        {name:'Todos', value: ''},
        {name:'Web', value: 'website'},
        {name:'Taquilla', value: 'taquilla'},
      ],
      rates: [
        {name:'Todos', value: ''},
        {name:'Local', value: 'local'},
        {name:'Regular', value: 'foreign'},
        {name:'Nacional', value: 'coupon'},
      ],
      status: [
        {name:'checkin', value: 'checkin'},
        {name:'confirmed', value: 'confirmed'},
        {name:'noshow', value: 'noshow'},
        {name:'pending', value: 'pending'},
      ],
      //unsubscribe: ,
    }
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'Taquilla')
  },
  async mounted() {
    let tourModel = new Tour
    let snapTours = await tourModel.all().get()
    snapTours.forEach(i => {
      this.tours.push({name: i.data().name, id: i.data().id})
    })
    //await this.searchReservations();
  },
  methods:{
    async searchReservations() {
      /*if (this.unsubscribe) {
        this.unsubscribe();
      }*/
      this.loading = true
      let filters = {
        tourId: this.filters.tour.id,
        status: this.filters.status.value,
        panel: this.filters.panel.value,
        rate: this.filters.rate.value,
        startDate: this.$moment(this.filters.startDate),
        endDate: this.$moment(this.filters.endDate),
      }
      this.result = await this.reservationModel.getDataTourReport(filters)
      //this.items = this.result.items
      this.loading = false
    },
    async generatePdf() {
      let filters = {...this.filters}
      filters.startDate = this.$moment(this.filters.startDate)
      filters.endDate = this.$moment(this.filters.endDate)
      const pdf = new ToursPDF({
        filters: filters,
        data:this.result,
        date:this.$moment()
      })
      this.loading = true
      const file = await pdf.create()
      file.download()
      this.loading = false
    }
  },
  //computed: mapState(['dialogConfirmationInfo'])
}
</script>